import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Doughnut, Pie } from "react-chartjs-2";
import "../App.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useAtom } from "jotai";
import { customAxios } from "../utils/CustomAxios";
import { authAtom } from "../atoms/authAtom";
import keycloak from "../auth/Keycloak";
import { useAuthInterceptor } from "../auth/AuthInterceptor";

ChartJS.register(ArcElement, Tooltip, Legend);

const Report: React.FC = () => {
  const [authState] = useAtom(authAtom);
  useAuthInterceptor();

  const [examData, setExamData] = useState<any[]>([]);
  const [wordCount, setWordCount] = useState(0);
  const [passedArtest, setPassedArtest] = useState(0);
  const [takenArtest, setTakenArtest] = useState(0);
  const [fictionCount, setFictionCount] = useState(0);
  const [nonfictionCount, setNonfictionCount] = useState(0);
  const [memberEngName, setMemberEngName] = useState<string>("");
  const [memberNo, setMemberNo] = useState(0);
  const [yearMonth, setYearMonth] = useState("2000-01");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const fetchDtos = async () => {
      try {
        console.log("access1");
        if (authState.accessToken) {
          console.log("access2");
          customAxios
            .get("arbp/reportperf", {
              params: { memberNo: memberNo, yearMonth: yearMonth },
            })
            .then((response) => {
              const data = response.data;
              setExamData(data);

              // examData 배열에서 첫 번째 요소의 memberEngName을 가져옴
              if (data.length > 0) {
                setMemberEngName(data[0].memberEngName);
              }

              // 통계 계산
              const totalWords = data.reduce(
                (sum: number, item: any) =>
                  item.pointAcv > 0 ? sum + item.wordCnt : sum,
                0
              );
              const passedTests = data.filter(
                (item: any) => item.pointAcv > 0
              ).length;
              const totalTests = data.length;
              const fictionBooks = data.filter(
                (item: any) => item.fiction === "Fiction"
              ).length;
              const nonfictionBooks = data.filter(
                (item: any) => item.fiction === "Nonfiction"
              ).length;

              setWordCount(totalWords);
              setPassedArtest(passedTests);
              setTakenArtest(totalTests);
              setFictionCount(fictionBooks);
              setNonfictionCount(nonfictionBooks);
            })
            .catch((error) => {
              console.error("데이터를 가져오는 중 오류 발생:", error);
            });
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchDtos();

    // yearMonth가 변경될 때마다 year와 month를 업데이트
    const yearValue = yearMonth.split("-")[0];
    const monthValue = monthNames[parseInt(yearMonth.split("-")[1], 10) - 1];
    setYear(yearValue);
    setMonth(monthValue);
  }, [authState, memberNo, yearMonth]);

  const chartData = {
    labels: ["Passed", "Failed"],
    datasets: [
      {
        data: [passedArtest, takenArtest - passedArtest],
        backgroundColor: ["#4CAF50", "#FF6384"],
        hoverBackgroundColor: ["#4CAF50", "#FF6384"],
      },
    ],
  };

  const fictionNonfictionData = {
    labels: ["Fiction", "Nonfiction"],
    datasets: [
      {
        data: [fictionCount, nonfictionCount],
        backgroundColor: ["#36A2EB", "#FF6384"],
        hoverBackgroundColor: ["#36A2EB", "#FF6384"],
      },
    ],
  };

  // 차트 옵션에서 legend 항상 표시
  const options = {
    plugins: {
      legend: {
        display: true, // Legend를 항상 표시
        position: "top" as const, // 명시적으로 "top"을 지정
        labels: {
          generateLabels: (chart: ChartJS) => {
            const datasets = chart.data.datasets;
            return datasets[0].data.map((value, index) => {
              let fillStyle: string | undefined;

              // backgroundColor가 배열인 경우 처리
              if (Array.isArray(datasets[0].backgroundColor)) {
                fillStyle = datasets[0].backgroundColor[index] as string;
              }

              return {
                text: `${chart.data.labels![index]}: ${value}`,
                fillStyle,
                hidden: !chart.isDatasetVisible(0),
              };
            });
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // API 요청 또는 필요한 작업 수행
    console.log("Submitted: ", { memberNo, yearMonth });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={memberNo}
          className="hidden-print"
          onChange={(e) => setMemberNo(parseInt(e.target.value, 10))}
        />
        <input
          type="text"
          value={yearMonth}
          className="hidden-print"
          onChange={(e) => setYearMonth(e.target.value)}
        />
        <button type="submit" className="hidden-print">
          Submit
        </button>
      </form>

      <Container maxWidth="md" style={{ marginTop: "20px" }}>
        {/* 헤더 섹션 */}
        <Card style={{ marginBottom: "20px" }}>
          <CardContent
            className="card-content"
            style={{
              backgroundColor: "#3f51b5",
              color: "#fff",
              WebkitPrintColorAdjust: "exact",
              printColorAdjust: "exact",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <Typography variant="h5" component="div" gutterBottom>
                Monthly Performance Report - {month} {year}
              </Typography>
              <Typography variant="body1">
                {memberEngName} {/* memberEngName 표시 */}
              </Typography>
            </div>
            <img
              src="img/arbplogo.svg"
              alt="Logo"
              className="w-24 h-auto ml-4"
              style={{ marginLeft: "auto" }}
            />
          </CardContent>
        </Card>

        <Grid item xs={12} md={6}>
          <Card style={{ marginBottom: "20px" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                총 읽은 단어 수
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Passed Practice 기준
              </Typography>
              <Typography variant="h4" color="primary">
                {wordCount.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* 차트와 단어 수 섹션 */}
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <Card style={{ marginBottom: "20px" }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  시험 통과율
                </Typography>
                <Box display="flex" justifyContent="center">
                  <Doughnut data={chartData} options={options} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card style={{ marginBottom: "20px" }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Fiction vs Nonfiction 비율
                </Typography>
                <Box display="flex" justifyContent="center">
                  <Pie data={fictionNonfictionData} options={options} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* 시험 결과 테이블 */}
        <Box mt={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Reading Practice
              </Typography>
              <Table component={Paper}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                    >
                      일자
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                    >
                      AR번호
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                    >
                      제목
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                    >
                      정답률
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                    >
                      포인트
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                    >
                      난이도
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {examData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      >
                        {new Date(row.quizDate).toISOString().split("T")[0]}
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      >
                        {row.arNo}
                      </TableCell>
                      <TableCell>
                        {row.title && row.fiction
                          ? `${row.title} (${row.fiction})`
                          : "외부 도서"}
                      </TableCell>
                      <TableCell>
                        {row.correctPer}% ({row.correct}/{row.correctPos})
                      </TableCell>
                      <TableCell>
                        {row.pointAcv} / {row.pointPos}
                      </TableCell>
                      <TableCell>{row.difficulty}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </>
  );
};

export default Report;
